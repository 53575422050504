import React from 'react';
import Layout from "../components/layout"
import HeroBanner from "../components/heroBanner"
import Preloader from "../components/preloader"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  data = data.allDatoCmsHomepage.nodes[0]
  return (
    <Layout>
      <Preloader />
      <HeroBanner video='https://player.vimeo.com/progressive_redirect/playback/749043686/rendition/1080p/file.mp4?loc=external&signature=4322b536dc015f9ce1cf161697c5c6e52fc2456232e5e2e96ebf2ec70193b6cb' title={data.heroTitle} image={data.heroImage} />
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    allDatoCmsHomepage {
      nodes {
        heroTitle
        heroImage {
          gatsbyImageData
        }
      }
    }
  }
`
