import React, { useEffect } from "react"
import { gsap } from "gsap"
import isChrome from "@braintree/browser-detection/is-chrome"
import {menuClose} from '../utils/panelActions'

const Preloader = () => {
  useEffect(() => {
    var wait = 3.5;
    if (window.innerWidth < 768) {
      wait = 5;
    }
    menuClose();
    document.body.classList.add("with-preloader");
    gsap.to(".preloader .title", { opacity: 1, duration: 1.25, delay: 1.25 })
    gsap.to(".spreloader .title, .spreloader .video", {
      opacity: 0, duration: 0.75, delay: 3.25, onComplete: function () {
        document.querySelector('.hero--video').play();
      }
    })
    gsap.to(".preloader", {
      height: "0px", duration: 1.25, delay: wait, ease: "Power3.easeInOut",
      onComplete: function () {
        document.body.classList.remove("with-preloader")
        document.querySelector('.preloader')?.remove();
      },
    })
  }, [])
  return (
    <div className="preloader flex light" style={{ background: '#957d5d' }}>
      <div className='inner flex'>
        <div className="title text-center ma h1 z-2 pos-rel max-625 m-t40">
          The pursuit of smarter living
        </div>

        <video playsInline={true} muted={true} autoPlay={true} className='bg-image video'>
          {typeof window !== 'undefined' && isChrome() && <source type='video/webm; codecs="vp8, vorbis"' src='https://ik.imagekit.io/62xcquqds/TRK_Loader_VP9_ehzH68IqC.webm' />}
          <source src="https://player.vimeo.com/progressive_redirect/playback/751444356/rendition/1080p/file.mp4?loc=external&signature=af0e1a345f83d65131e50ccb75a70256ee4b7fa7559613ee6fcd484c9eff73e2" type='video/mp4; codecs="hvc1"' />

        </video>
      </div>
    </div>
  )
}
export default Preloader
